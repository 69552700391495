const Constantes = {
    tituloSitio: "Administración",
    sitename: "GanaConcursos",
    estadosPublicado: ["Publicado", "No Publicado"],
    estadoActivo: "ACT",
    estadoInactivo: "INA",
    estRegistro: [
        {label: 'Activo', value: 'ACT'},
        {label: 'Inactivo', value: 'INA'}
    ],
    estEstudiante: [
        {label: 'Registrado', value: 1},
        {label: 'Pagado', value: 2},
        {label: 'Inactivo', value: 3},
    ],
    tipoDatoPerfil:[
        {label: 'Texto', value:'t'},
        {label: 'Número', value:'n'},
        {label: 'Correo', value:'c'},
        {label: 'Whatsapp', value:'w'},
        {label: 'Teléfono', value:'p'},
        {label: 'Url', value:'u'},
        {label: 'Fecha', value:'f'},
    ],
    planPro : 'pro',
    tipoBaseConcursoQuiniela: 'quiniela', //es importante que sea string y no numerico
    tipoBaseConcursoTrivia: 'trivia',
    tipoBaseConcursoCricigrama: 'cruci'
}

export default Constantes;