import React, { Suspense } from 'react';
import './layout.css';
import { Switch, Route } from "react-router-dom";
import { useCookies } from 'react-cookie';
import Header from './Header';
import MenuLeft from './Menu';
import Inicio from './Inicio';

const Empresa = React.lazy(() => import('../empresas/Empresa'));
const Concurso = React.lazy(() => import('../concursos/Concurso'));
const Integracion = React.lazy(() => import('./Integracion'));
const Planes = React.lazy(() => import('../planes/Planes'));

const Layout = () => {  
    const [cookies] = useCookies(['user']); 
    
    return (
        <div>
            <Header/>
            <div className="container-fluid">
                <div className="row">
                    <MenuLeft cookies={cookies}/>
                    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                    <Switch>
                        <Route path="/empresas">
                            <Suspense fallback={<div>Cargando...</div>}>
                                <Empresa />
                            </Suspense>
                        </Route>
                        <Route path="/concursos">
                            <Suspense fallback={<div>Cargando...</div>}>
                                <Concurso />
                            </Suspense>
                        </Route>
                        <Route path="/integracion">
                            <Suspense fallback={<div>Cargando...</div>}>
                                <Integracion />
                            </Suspense>
                        </Route>
                        <Route path="/planes">
                            <Suspense fallback={<div>Cargando...</div>}>
                                <Planes />
                            </Suspense>
                        </Route>
                        <Route path="/">
                            <Inicio />
                        </Route>
                    </Switch>
                    </main>
                </div>
            </div>
        </div>
    )
}

export default Layout 