/**
 * An async fetch with error catch
 * @param url
 * @param data
 * @returns {Promise.<*>}
 */
export const request = async (url, data = {}) => {
    try {
      const response = await fetch(url, data);
      return response;
    } catch (err) {
      console.log(err);
      return { error: err };
    }
};

export const getLabelfromValue = (array,key) => {
  const objIndex = array.findIndex((obj => obj.value === key));
  return array[objIndex].label;
}