import React from 'react' ;
import { Link } from "react-router-dom";

const Inicio = () => {
    return <div>
        <h2>Bienvenido a Ganaconcursos!</h2>
        <p>Un sistema de marketing para publicar concursos online y poder hacer campañas de marketing con la información de los jugadores.</p>
        <p>En tu sistema de administración podrás realizar lo siguiente:</p>
        <ul>
            <li>Crear y gestionar empresas clientes con todos sus datos para que se puedan usar en los concursos.</li>
            <li>Crear concursos de varios tipos disponibles de acuerdo al plan que tengas activado, puedes ver los distintos planes dando click <Link to="/planes">aquí</Link>.</li>
            <li>Cada concurso tiene un código único el cual podrás usar para publicar el concurso de la siguiente forma: www.ganaconcursos.com/c/---codigo--- </li>
            <li>Una vez publicado el concurso podrás promocionarlo para que se registren jugadores, todos los jugadores deben ingresar su correo y su nombre, si eres premium podrás solicitar más información a los jugadores y tener una mejor base de datos</li>
            <li>Cuando haya terminado el concurso puedes ponerlo como no publicado y descargar la base de participantes.  Si tienes el plan gratuito podrás descargar hasta 500 jugadores.</li>
        </ul>
        </div>;
}

export default Inicio;