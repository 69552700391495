const en = {
    translation: {
        "Login": "Create contests and manage participant data with a powerful digital marketing tool.",
        "Usuario": "User",
        "Clave": "Password",
        "Entrar": "Sign in",
        "Registrate": "Sign up",
        "EresNuevo": "Not a user?",
        "Registrarme": "Sign in",
        "Nombre": "First name",
        "Apellido": "Last name",
        "Correo":"Email",
        "aceptarregistro": "I accept the policies and conditions of use",
        "errorgeneral": "Ups! sorry we have network problems, please try again later.",
        "Inicio": "Home",
        "Empresas": "Company",
        "Concursos": "Contests",
        "Participantes": "Players",
        "Email Marketing": "Email Marketing",
        "Salir": "Sign out",
        "Hazte premium": "Premium plans",
        "administracionempresas": "Manage Companies",
        "Editar Empresa": "Company Edition",
        "Guardar":"Save",
        "Crear": "New",
        "Eliminar": "Delete",
        "Si": "Yes",
        "No": "Not",
        "ACTIVO": "ACTIVE",
        "INACTIVO": "INACTIVE",
        "item guardado": "Successfully Saved",
        "administracionconcursos": "Manage Contests",
        "titulocrearempresasconcurso": "Make your first Contest",
        "mensajecrearempresaconcurso": "Before creating a contest you must create a company to assign contests to it, in this way the data of the company will be displayed in the contest.",
        "Datos Generales": "General data",
        "terminosycondiciones": "Policies and Conditions",
        "Diseño": "Design",
        "Seleccione la Empresa": "Company",
        "Código": "Code",
        "Nombre del Concurso": "Contest name",
        "Estado": "Status",
        "Descripción y detalle del concurso": "Contest's Details and Descriptions",
        "Hazte premium Mensaje": "To access this feature, become a premium and customize your contests."
    }
}

export default en;