import React, { useState, useRef } from 'react';
import AdminServiciosAPI from '../servicios/AdminServicios';
import { useTranslation } from "react-i18next";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useCookies } from 'react-cookie';
import { Messages } from 'primereact/messages';
import {Checkbox} from 'primereact/checkbox';
import { Divider } from 'primereact/divider';
import {Password} from 'primereact/password';

function Registro() {  
    const [usuarionuevo, setUsuarioNuevo] = useState('');
    const [clavenueva, setClaveNueva] = useState('');
    const [nombre, setNombre] = useState('');
    const [apellido, setApellido] = useState('');
    const [acepto, setAcepto] = useState(false);
    const [, setCookie] = useCookies(['user']);
    const [cargando, setCargando] = useState(false);
    const mensaje = useRef(null);
    const { t } = useTranslation();

    const _registrar = async (usuario,clave) => {
        setCargando(true);
        const response = await AdminServiciosAPI.registro({usuario: usuarionuevo,clave: clavenueva,datos:{nombre,apellido}});
        if(response.error){
            mensaje.current.show({
                severity: 'warn',
                sticky: true,
                detail: t('errorgeneral')
            });
        }else if(!response.ok){
            const errormsj = await response.json();
            mensaje.current.show({
                severity: 'info',
                sticky: true,
                detail: errormsj
            });
        }else{
            let datosUsuario = await response.json();
            setCookie('GCUSUARIO', datosUsuario.usuario, { path: '/' });
            setCookie('GCCOOKIEUSUARIO', datosUsuario.cookie, { path: '/' });
            window.location.reload(false);
        }
        setCargando(false);
    }

    return (
        <div>
            <Messages ref={mensaje} />
            <form className="form-horizontal">
                <div className="form-group mb-2">
                    <label>{t('Nombre')}</label>
                    <InputText className="form-control p-inputtext" value={nombre} onChange={(e) => setNombre(e.target.value)} required/>
                </div>
                <div className="form-group mb-2">
                    <label>{t('Apellido')}</label>
                    <InputText className="form-control p-inputtext" value={apellido} onChange={(e) => setApellido(e.target.value)} required/>
                </div>
                <div className="form-group mb-2">
                    <label>{t('Correo')}</label>
                    <InputText className="form-control p-inputtext" value={usuarionuevo} onChange={(e) => setUsuarioNuevo(e.target.value)} required/>
                </div>
                <div className="form-group mb-3">
                    <label className="d-block">{t('Clave')}</label>
                    <Password value={clavenueva} onChange={(e) => setClaveNueva(e.target.value)} required/>
                </div>
                <div className="p-field-checkbox">
                    <Checkbox className="me-2" inputId="aceptacion" checked={acepto} onChange={e => setAcepto(e.checked)} />
                    <label htmlFor="aceptacion">{t('aceptarregistro')}</label>
                </div>
                <Divider />
                <div className="form-group text-center">
                    <Button type="button" loading={cargando} className="btn btn-success w-100 mt-3" label={t('Registrarme')} onClick={() =>_registrar()} />
                </div>
            </form>
        </div>
    )
}

export default Registro