import React, { useState, useRef } from 'react';
import AdminServiciosAPI from '../servicios/AdminServicios';
import Registro from './Registro';
import { useTranslation } from "react-i18next";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useCookies } from 'react-cookie';
import { Messages } from 'primereact/messages';
import { Card } from 'primereact/card';
import { Divider } from 'primereact/divider';
import { Dialog } from 'primereact/dialog';

function Entrar() {  
    const [usuario, setUsuario] = useState('');
    const [clave, setClave] = useState('');
    const [, setCookie] = useCookies(['user']);
    const [cargando, setCargando] = useState(false);
    const [verRegistro, setVerRegistro] = useState(false);
    const mensaje = useRef(null);
    const { t } = useTranslation();

    const _entrar = async (usuario,clave) => {
        setCargando(true);
        const response = await AdminServiciosAPI.login({usuario,clave});
        if(response.error){
            mensaje.current.show({
                severity: 'warn',
                sticky: true,
                detail: t('errorgeneral')
            });
        }else if(!response.ok){
            const errormsj = await response.json();
            mensaje.current.show({
                severity: 'info',
                sticky: true,
                detail: errormsj
            });
        }else{
            const datosUsuario = await response.json();
            datosUsuario.usuario.datos = JSON.parse(datosUsuario.usuario.datos);
            setCookie('GCUSUARIO', datosUsuario.usuario, { path: '/' });
            setCookie('GCCOOKIEUSUARIO', datosUsuario.cookie, { path: '/' });
            window.location.reload(false);
        }
        setCargando(false);
    }

    const _registrar = () => {
        setVerRegistro(true);
    }

    return (
        <Card>
            <Messages ref={mensaje} />
            <form className="form-horizontal">
                <div className="form-group">
                    <label>{t('Correo')}</label>
                    <InputText className="form-control p-inputtext" value={usuario} onChange={(e) => setUsuario(e.target.value)} />
                </div>
                <div className="form-group">
                    <label>{t('Clave')}</label>
                    <InputText className="form-control p-inputtext" type="password" value={clave} onChange={(e) => setClave(e.target.value)} />
                </div>
                <div className="form-group text-center">
                    <Button type="button" loading={cargando} className="btn btn-primary w-100 mt-3" label={t('Entrar')} onClick={() =>_entrar(usuario,clave)} />
                </div>
                <Divider align="center">
                <span>{t('EresNuevo')}</span>
                </Divider>
                <div className="form-group text-center">
                    <Button type="button" className="btn btn-success w-100 mt-3" label={t('Registrate')} onClick={() =>_registrar()} />
                    <Dialog header={t('Registrate')} position="top" visible={verRegistro} onHide={() => setVerRegistro(false)} breakpoints={{'960px': '75vw'}}>
                        <Registro />
                    </Dialog>
                </div>
            </form>
        </Card>
    )
}

export default Entrar