import React, { useRef } from 'react' ;
import { Constantes } from '../comun';
import { useCookies } from 'react-cookie';
import { Menu } from 'primereact/menu';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const Header = () => {  
	const menu = useRef(null);
	let history = useHistory();
	const [cookies, , removeCookie] = useCookies(['user']);
	const { t } = useTranslation();
	const items = [
		{
			label: t('Integración'),
			icon: 'pi pi-link',
			command: () => history.push("/integracion")
		},
		{
			label: t('Salir'),
			icon: 'pi pi-sign-out',
			command: () => {cerrarSesion()}
		}
	];
	function cerrarSesion() {
		removeCookie('GCUSUARIO');
		removeCookie('GCCOOKIEUSUARIO');
		window.location.href='/';
	}

	return (
		<header className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
			<a className="navbar-brand col-md-3 col-lg-2 me-0 px-3" href="#">{Constantes.sitename}</a>
			<button className="navbar-toggler d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
				<span className="navbar-toggler-icon"></span>
			</button>
			<ul className="navbar-nav user-menu px-3">
				<li className="nav-item dropdown has-arrow main-drop">
				<Menu model={items} popup ref={menu} id="popup_menu" />
				<a href="#" onClick={(event) => menu.current.toggle(event)} className="dropdown-toggle nav-link" data-toggle="dropdown">
					<span className="user-img"><img src={process.env.PUBLIC_URL +'/assets/img/system-user.png'}/>
					<span className="status online"></span></span>
					<span className="m-2">{cookies.GCUSUARIO.datos.nombre}</span>
				</a>
				</li>
			</ul>
		</header>
	);
}

export default Header