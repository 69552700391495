import { request } from'./Omni';
import { Config } from '../comun';

const AdminServiciosAPI = {
    login: async ({usuario, clave}) => {
      const _url = `${Config.apiGateway}/usuarios`;
      return request(_url, {
        method: "POST",
        body: JSON.stringify({
          usuario:usuario,
          clave:clave,
        }),
        headers: {"Content-Type": "application/json"}
      });
    },
    registro: async (datos) => {
      const _url = `${Config.apiGateway}/usuarios`;
      return request(_url, {
        method: "PUT",
        body: JSON.stringify(datos),
        headers: {"Content-Type": "application/json"}
      });
    },
    getRegistros: async (cookieusuario,parametro) => {
      const _url = `${Config.apiGateway}/${parametro}`;
      return request(_url, {
        method: "GET",
        headers: {"Content-Type": "application/json", "authorization":cookieusuario},
      });
    },
    getItems: async (cookieusuario,tabla,parametros) => {
        const _url = `${Config.apiGateway}/${tabla}/items`;
        return request(_url, {
          method: "POST",
          headers: {"Content-Type": "application/json", "authorization":cookieusuario},
          body: JSON.stringify({
            parametros:parametros,
          }),
        });
      },
    nuevoRegistro: async (cookieusuario,tabla,parametros) => {
        const _url = `${Config.apiGateway}/${tabla}`;
        return request(_url, {
            method: "PUT",
            headers: {"Content-Type": "application/json", "authorization":cookieusuario},
            body: JSON.stringify(parametros),
        });
    },
    editarRegistro: async (cookieusuario,tabla,parametros) => {
        const _url = `${Config.apiGateway}/${tabla}`;
        return request(_url, {
            method: "POST",
            headers: {"Content-Type": "application/json", "authorization":cookieusuario},
            body: JSON.stringify(parametros),
        });
    },
    eliminarRegistro: async (cookieusuario,tabla,parametros) => {
        const _url = `${Config.apiGateway}/${tabla}`;
        return request(_url, {
            method: "DELETE",
            headers: {"Content-Type": "application/json", "authorization":cookieusuario},
            body: JSON.stringify(parametros),
        });
    },
    enviarArchivo: async (cookieusuario,url,parametros) => {
        const _url = `${Config.apiGateway}/files/${url}`;
        return request(_url, {
          method: "POST",
          headers: {"Content-Type": "application/json", "authorization":cookieusuario},
          body: JSON.stringify(parametros),
        });
    }
}

export default AdminServiciosAPI;