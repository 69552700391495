import { InputText } from "primereact/inputtext";
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { Country, City }  from 'country-state-city';
import { Dropdown } from 'primereact/dropdown';
import JoditEditor from "jodit-react";
import {ColorPicker} from 'primereact/colorpicker';
import InputArchivo from "./InputIArchivo";

const InputVariable = ({valor,id,index,dato,setValorDato}) => {
    let input;
    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Claro'
    });
    switch (dato.tipo) {
        case 'E':
            input = <InputText id={id} onChange={(e) => setValorDato(e.target.value,index,dato.padre)} className="p-d-block form-control" value={valor[index]} maxLength="100" keyfilter="email"/>
            break;
        case 'F':
            const valorf = valor[index]?new Date(valor[index]):null;
            input = <Calendar id={id} onChange={(e) => setValorDato(e.value,index,dato.padre)} value={valorf} locale="es" dateFormat="dd/mm/yy" mask="99/99/9999" />
            break;
        case 'W':
        case 'P':
            input = <InputText id={id} onChange={(e) => setValorDato(e.target.value,index,dato.padre)} className="p-d-block form-control" value={valor[index]} maxLength="20" keyfilter="num" placeholder={dato.placeholder?dato.placeholder:''}/>
            break;
        case 'PAIS':
            input =  <Dropdown value={valor[index]} options={Country.getAllCountries()} onChange={(e) => setValorDato(e.value,index,dato.padre)} optionLabel="name" filter showClear filterBy="name" className="w-100" />
            break;
        case 'CIUDAD':
            const countryCode = valor['pais']?valor['pais'].isoCode:null;
            input =  <Dropdown value={valor[index]} options={City.getCitiesOfCountry(countryCode)} onChange={(e) => setValorDato(e.value,index,dato.padre)} optionLabel="name" filter showClear filterBy="name" className="w-100" />
            break;
        case 'D':
            if(typeof dato.opciones.label === 'undefined'){
                input =  <Dropdown value={valor[index]} options={dato.opciones.tabla} onChange={(e) => setValorDato(e.value,index,dato.padre)}  showClear className="w-100" />
            }else{
                input =  <Dropdown value={valor[index]} options={dato.opciones.tabla} onChange={(e) => setValorDato(e.value,index,dato.padre)} optionLabel={dato.opciones.label} filter showClear filterBy={dato.opciones.label} className="w-100" />
            }
            break;
        case 'CODIGO':
            input = <InputText id={id} className="p-d-block form-control" value={valor[index]} disabled/>
            break;
        case 'TEXTO':
            input = <JoditEditor
                    value={valor[index]}
                    config={{
                        readonly: false,
                        height: '300px',
                        language: 'es',
                        i18n: 'es',
                        link: {
                            processVideoLink: false,
                            processPastedLink: false
                        },
                        askBeforePasteHTML: false,
                        askBeforePasteFromWord: false,
                        defaultActionOnPaste: "insert_clear_html"
                    }}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={newContent => setValorDato(newContent,index,dato.padre)} // preferred to use only this option to update the content for performance reasons
                />
            break;
        case 'COLOR':
            input = <ColorPicker value={valor[index]} onChange={(e) => setValorDato(e.value,index,dato.padre)} format="rgb" defaultColor="ffffff" />
            break;
        case 'ARCHIVO':
            input = <InputArchivo valor={valor} id={id} index={index} dato={dato} setValorDato={setValorDato} />
            break;
        default:
            input = <InputText id={id} onChange={(e) => setValorDato(e.target.value,index,dato.padre)} className="p-d-block form-control" value={valor[index]} maxLength="100" placeholder={dato.placeholder?dato.placeholder:''}/>
            break;
    }
    return input;
}

const InputTipoDato = ({listado, valor, setValorDato, t}) => {
    let valorfinal;
    return (<div>
        {Object.keys(listado).map((e,i) => {
            valorfinal = valor;
            if(typeof listado[e].padre !== 'undefined'){
                valorfinal = valor[listado[e].padre];
            }
            return <div className="mb-3" key={`imp_${i}`}>
                <label htmlFor={e} className="form-label d-block">{t(listado[e].label)}</label>
                {listado[e].label && <div id={`${e}Help`} className="form-text">{listado[e].help}</div>}
                <InputVariable id={e} valor={valorfinal} index={e} dato={listado[e]} setValorDato={setValorDato}/>
            </div>
        })}
        </div>
    );
}

export default InputTipoDato;