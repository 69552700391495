const es = {
    translation: {
        "Login": "Crea concursos y administra los datos de los participantes con una potente herramienta de marketing digital.",
        "Usuario": "Usuario",
        "Clave": "Clave",
        "Entrar": "Entrar",
        "Registrate": "Regístrate",
        "EresNuevo": "¿Eres Nuevo?",
        "Registrarme": "Registrarme",
        "Nombre": "Nombre",
        "Apellido": "Apellido",
        "Correo": "Correo",
        "aceptarregistro": "Acepto los términos y condiciones de uso",
        "errorgeneral": "Lo sentimos tenemos problemas de red, intente en unos minutos.",
        "Inicio": "Inicio",
        "Empresas": "Empresas",
        "Concursos": "Concursos",
        "Participantes": "Participantes",
        "Email Marketing": "Email Marketing",
        "Salir": "Salir",
        "Hazte premium": "Hazte premium",
        "administracionempresas": "Administración de Empresas",
        "Editar Empresa": "Editar Empresa",
        "Guardar":"Guardar",
        "Crear": "Crear",
        "Eliminar": "Eliminar",
        "Si": "Si",
        "No": "No",
        "ACTIVO": "ACTIVO",
        "INACTIVO": "INACTIVO",
        "item guardado": "Guardado con éxito",
        "administracionconcursos": "Administración de Concursos",
        "titulocrearempresasconcurso": "Pronto podrás crear Concursos",
        "mensajecrearempresaconcurso": "Antes de crear un concurso debes crear una empresa para asignarle concursos, de esta forma se mostrarán los datos de la empresa en el concurso.",
        "Datos Generales": "Datos Generales",
        "terminosycondiciones": "Términos y Condiciones",
        "Diseño": "Diseño",
        "Hazte premium Mensaje": "Para acceder a esta función hazte premium y personaliza tus concursos."
    }
}

export default es;