import React, { useEffect } from 'react'  
import './login.css';  
import Entrar from '../login/Entrar';
import { useTranslation } from "react-i18next";

function Login () {   
    const { t } = useTranslation();
    useEffect(() => {
        document.body.classList.add("login-page");
        //document.body.classList.add("justify-content-center");
    },[]);  

    return (
        <div className="container">
            <div className="row d-flex align-items-center">
                <div className="col-md-8">
                    <h1 className="text-white">GanaConcursos</h1>
                    <p className="lead text-white">{t('Login')}</p>
                </div>
                <div className="col-md-4">
                    <Entrar/>
                </div>
            </div>
        </div>
    )
}

export default Login