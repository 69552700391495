import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import Layout from './core/Layout';
import Login from './core/Login';
import PrimeReact from 'primereact/api';
import { useCookies } from 'react-cookie';
import {
  BrowserRouter as Router
} from "react-router-dom";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { es, en } from './traducciones';

PrimeReact.ripple = true;

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      es: es,
      en: en
    },
    lng: "es", // if you're using a language detector, do not define the lng option
    fallbackLng: "es",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

function App() {
  const [cookies] = useCookies(['user']);
  return (
    <Router>
      <div className="App">
        {cookies.GCUSUARIO ? <Layout/>:<Login/>}
      </div>
    </Router>
  );
}

export default App;
