import React from 'react';
import { Config } from '../comun';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MenuLeft = (props) => {  
    const { t } = useTranslation();
    
    return (
        <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
        <div className="position-sticky pt-3">
            <ul className="nav flex-column">
                {Config.menu.map((m,i) => {
                    return <li className="nav-item" key={`menu_${i}`}>
                        <Link to={m.url} className="nav-link"><i className={m.icon} aria-hidden="true"></i> {t(m.label)}</Link>
                    </li>
                })}
                {props.cookies.GCUSUARIO.datos.idusuario===Config.plasma && <li className="nav-item" key="menu_admin">
                    <Link to='/admin/juegos' className="nav-link"><i className='pi pi-cog' aria-hidden="true"></i> Administración</Link>
                </li>}
            </ul>
            <Link to="/planes" className="btn btn-primary mt-3 w-100"><i className="pi pi-shield"></i> {t('Hazte Premium')}</Link>
        </div>
        </nav>
    );
}

export default MenuLeft